@font-face {
  font-family: "merriweatherblack";
  src: url("../fonts/merriweather-black-webfont.woff2") format("woff2"),
    url("../fonts/merriweather-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "merriweatherbold";
  src: url("../fonts/merriweather-bold-webfont.woff2") format("woff2"),
    url("../fonts/merriweather-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "merriweatheritalic";
  src: url("../fonts/merriweather-italic-webfont.woff2") format("woff2"),
    url("../fonts/merriweather-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "merriweatherregular";
  src: url("../fonts/merriweather-regular-webfont.woff2") format("woff2"),
    url("../fonts/merriweather-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "staatlichesregular";
  src: url("../fonts/staatliches-regular-webfont.woff2") format("woff2"),
    url("../fonts/staatliches-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
