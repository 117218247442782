@import "./global/reset";
@import "./global/fonts";
@import "./global/vars";
@import "./global/functions";
@import "../../node_modules/swiper/swiper";
@import "../../node_modules/swiper/modules/navigation/navigation";
@import "./Modal";
@import "./CookieBar";

html,
body {
  overflow-x: hidden;
}

html {
  &.open-modal {
    overflow: hidden;
  }
}

body {
  &.open-modal {
    overflow: hidden;
  }
}

html {
  @media screen and (max-width: 540px) {
    font-size: 15px;
  }
}

.header {
  text-align: center;
  position: relative;

  &__figure {
    margin: 0;
    overflow: hidden;
    background-image: url('../assets/header-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60% center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 992px) {
      background-position: 100% center;
    }

    .image {
      height: 100%;

      @media screen and (min-width: 992px) {
        height: 50%;
      }

      @media screen and (min-width: 1600px) {
        height: 80%;
      }

      &.left {
        display: none;

        @media screen and (min-width: 992px) {
          display: block;
          position: absolute;
          left: -3vw;
          bottom: 0;
        }

        @media screen and (min-width: 1600px) {
          left: -6.5vw;
        }
      }

      &.right {
        position: absolute;
        right: -35%;
        bottom: 0;

        @media screen and (min-width: 992px) {
          right: -8vw;
        }

        @media screen and (min-width: 1600px) {
          right: -13vw;
        }
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__body {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: cp(700);
    height: 40vw;
    padding-top: 5vw;

    .desktop {
      display: none;

      @media screen and (min-width: 992px) {
        display: block;
      }
    }

    .mobile {
      @media screen and (min-width: 992px) {
        display: none;
      }
    }

    @media screen and (max-width: 992px) {
      height: auto;
    }

    strong {
      z-index: 5;
      position: relative;
      color: $color2;
      font-size: cp(120);
      line-height: 0.833em;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-family: $title;
      margin-bottom: cp(50);
      margin-top: -10%;

      span {
        text-decoration: underline;
      }
    }

    h1 {
      color: $color2;
      font-size: cp(40);
      line-height: 1.275em;
      font-family: $bold;
      margin-bottom: cp(50);
      max-width: cp(700);

      span {
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: cp(50);

    @media screen and (max-width: 992px) {
      top: 5vw;
      left: 5vw;
      width: 25vw;
    }
  }

  &__swoosh {
    position: absolute;
    bottom: cp(-20);
    left: calc(50% - cp(120));
    transform: rotate(-25deg);
    width: cp(288.11);

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  @media screen and (max-width: 992px) {
    .header__figure {
      height: 70vw;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .header__img {
        height: 100%;
        width: auto;
      }
    }

    .header__body {
      align-items: flex-start;
      min-height: auto;

      strong {
        width: 60%;
        height: 70vw;
        text-align: center;
        color: $color6;
        font-size: 15vw;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        margin: 0;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 15%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
          color: $color2;
        }
      }

      h1 {
        display: block;
        font-size: 7vw;
        margin: 75vw auto 0 auto;
        max-width: 65vw;

        span {
          color: $color1;
        }
      }
    }
  }
}

.line-title {
  display: inline-block;
  font-family: $black;
  color: $color6;
  font-size: cp(25);
  line-height: cp(38);

  span {
    display: inline-block;
    background-color: $color1;
    padding: cp(5) cp(10) 0;
    margin-bottom: cp(5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 600px) {
    background-color: $color1;
    padding: cp(5) cp(10);

    br {
      display: none;
    }

    span {
      background-color: transparent;
      padding: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: 768px) {
    font-size: cp(40);
    line-height: cp(51);
  }
}

.content,
.video,
.articles {
  max-width: 761px;
  margin-inline: auto;
  padding-left: cp(30);
  padding-right: cp(30);
  margin-top: cp(50);

  @media screen and (min-width: 992px) {
    margin-top: cp(70);
  }

  .line-title {
    margin-bottom: cp(40);

    @media screen and (min-width: 992px) {
      margin-bottom: cp(70);
    }
  }

  p {
    font-family: $regular;
    font-size: cp(16);
    line-height: cp(25);
    color: $color2;

    strong {
      font-family: $black;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0;
  }
}

.video {
  &__title {
    &.line-title {
      margin-bottom: cp(40);

      @media screen and (min-width: 992px) {
        margin-bottom: cp(50);
      }
    }

    span {
      background-color: $color3;
    }

    @media screen and (min-width: 600px) {
      background-color: $color3;
    }
  }
}

.articles {
  &__title {
    &.line-title {
      margin-bottom: cp(-10);

      @media screen and (min-width: 768px) {
        margin-bottom: cp(70);
      }
    }

    span {
      background-color: $color2;
    }

    @media screen and (min-width: 600px) {
      background-color: $color2;
    }
  }
}

.article {
  display: grid;
  grid-template-columns: 28% 40% 32%;
  align-items: center;
  justify-content: center;
  max-width: 1180px;
  margin: cp(20) auto cp(96);

  @media screen and (max-width: 1240px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (max-width: 992px) {
    display: flex;
    grid-template-columns: auto;
    position: relative;
    padding-left: 276px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: cp(175) auto;
  }

  @media screen and (max-width: 768px) {
    padding-left: 30px;
    align-items: center;

    &:last-of-type {
      margin-bottom: cp(60);
    }
  }

  &:first-of-type {
    margin-top: cp(160);
  }

  &>* {
    padding: 0 30px;
  }

  &__figure {
    position: relative;

    @media screen and (max-width: 992px) {
      width: 276px;
      flex: 0 0 276px;
      position: absolute;
      left: 0;
      top: 0;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      padding: 0;
      margin-bottom: -15%;
    }

    &:after {
      content: "";
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      top: 0;
      border-radius: 50%;
      background-color: $color2;
      z-index: 1;
      transform: translateY(15px);

      @media screen and (max-width: 768px) {
        left: 0;
        right: 0;
        transform: translateY(-15px);
      }
    }
  }

  &__img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    transform: translateX(-15px);
  }

  &__body {
    padding: 0 50px;

    @media screen and (max-width: 992px) {
      padding: 30px 40px 40px 40px;
      border-radius: cp(60);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: 1px solid $color2;
    }

    @media screen and (max-width: 768px) {
      padding: 40px 40px 30px 40px;
      display: flex;
      justify-content: center;
      background-color: rgba($color6, 0.9);
      z-index: 2;
    }

    ul {
      li {
        padding-left: cp(30);
        position: relative;
        color: $color1;
        font-family: $regular;
        font-size: cp(16);
        line-height: 1.563em;

        &:not(:last-child) {
          margin-bottom: cp(20);
        }

        &:before {
          content: "";
          width: cp(16);
          height: cp(12);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='11.034' viewBox='0 0 16 11.034'%3E%3Cpath id='Checkbox' d='M6.345,11.034,0,4.69,1.287,3.4,6.345,8.368,14.713,0,16,1.287Z' fill='%23ded434'/%3E%3C/svg%3E%0A");
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          top: cp(5);
        }
      }
    }
  }

  &__title {
    text-transform: lowercase;
    font-family: $regular;
    font-size: cp(40);
    line-height: cp(47);
    color: $color2;
    margin-bottom: cp(20);

    span {
      font-family: $black;
    }

    @media screen and (max-width: 768px) {
      position: absolute;
      bottom: 100%;
      background-color: $color2;
      padding: cp(20);
      color: $color6;
      border-radius: cp(50);
      margin-bottom: 0;
      max-width: calc(100% - 60px);
      text-align: center;
    }

    @media screen and (max-width: 540px) {
      font-size: cp(25);
    }
  }

  &__quote {
    background-color: $color2;
    padding: cp(40) cp(60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: cp(35);
    min-height: cp(304);
    position: relative;

    @media screen and (max-width: 992px) {
      border-radius: cp(60);
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    &:after {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 24px solid $color2;
      border-bottom: 20px solid transparent;
      left: cp(60);
      bottom: cp(-20);

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    p {
      color: $color6;
      font-size: cp(19);
      line-height: 1.579em;
      font-family: $italic;
    }

    cite {
      color: $color6;
      font-size: cp(13);
      line-height: 1.538em;
      font-family: $italic;
      margin-top: cp(35);

      span {
        color: $color3;
        display: block;

        a {
          color: $color3;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &:nth-of-type(5) {

    .article__title {
      background-color: $color3;

      @media screen and (min-width: 768px) {
        background-color: transparent;
      }
    }

    .article__body {
      border-color: $color3;
    }

    .article__figure {
      &:after {
        background-color: $color3;
      }
    }

    .article__quote {
      background-color: $color3;

      p,
      cite {
        color: $color2;

        a {
          color: $color1;
        }
      }

      &:after {
        border-left-color: $color3;
      }
    }
  }

  &:nth-of-type(6) {

    .article__title {
      background-color: $color1;

      @media screen and (min-width: 768px) {
        background-color: transparent;
      }
    }

    .article__body {
      border-color: $color1;
    }

    .article__figure {
      &:after {
        background-color: $color1;
      }
    }

    .article__quote {
      background-color: $color1;

      p,
      cite {
        a {
          color: $color2;
        }
      }

      &:after {
        border-left-color: $color1;
      }
    }
  }

  &:nth-of-type(7) {

    .article__title {
      background-color: $color4;

      @media screen and (min-width: 768px) {
        background-color: transparent;
      }
    }

    .article__body {
      border-color: $color4;
    }

    .article__figure {
      &:after {
        background-color: $color4;
      }
    }

    .article__quote {
      background-color: $color4;

      p,
      cite {
        color: $color6;
      }

      &:after {
        border-left-color: $color4;
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;

  &__header {
    padding: cp(50) 0;
    max-width: 670px;
    text-align: center;
    margin: 0 auto;

    &__icon {
      margin-bottom: cp(25);

      @media screen and (max-width: 540px) {
        width: 20vw;
      }
    }
  }

  &__title {
    font-size: cp(35);
    line-height: 1.286em;
    font-family: $regular;
    color: $color1;

    span {
      display: block;
      font-family: $black;
    }

    @media screen and (max-width: 540px) {
      font-size: 25px;
    }
  }

  &__body {
    padding: 0 0 cp(100) 0;
  }

  &--breaker {
    background-color: $color2;
    position: relative;
    overflow: hidden;
    margin-top: cp(-80);

    @media screen and (min-width: 768px) {
      margin-top: cp(140);
    }

    .section__title {
      font-size: cp(100);
      line-height: 1em;
      font-family: $title;
      text-transform: uppercase;
      color: $color3;

      @media screen and (max-width: 992px) {
        font-size: cp(80);
      }

      @media screen and (max-width: 768px) {
        font-size: cp(65);
      }

      @media screen and (max-width: 540px) {
        font-size: 50px;
      }
    }

    .section__lead {
      p {
        color: $color6;
      }
    }

    &>* {
      position: relative;
      z-index: 2;
    }

    &__bg {
      position: absolute;
      left: 90%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.15;
      z-index: 0;
    }
  }
}

.btn {
  background-color: $color3;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  color: $color2;
  font-family: $title;
  font-size: cp(16);
  line-height: 1em;
  text-transform: uppercase;
  border-radius: cp(20);
  padding: cp(11) cp(20);

  &--arrow {
    justify-content: space-between;

    &:after {
      content: "";
      width: cp(14);
      height: cp(14);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.333' height='14.333' viewBox='0 0 14.333 14.333'%3E%3Cpath id='Path_10' data-name='Path 10' d='M7.167,0l-1.3,1.3L10.8,6.236H0V8.1H10.8L5.864,13.03l1.3,1.3,7.167-7.167Z' fill='%234d2883'/%3E%3C/svg%3E%0A");
      display: inline-block;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  text-decoration: none;
  height: 100%;

  &__sup {
    background-color: $color1;
    color: $color6;
    text-transform: uppercase;
    font-family: $bold;
    font-size: cp(11);
    text-align: center;
    padding: cp(8) cp(20);

    @media screen and (max-width: 768px) {
      background-color: transparent;
      color: $color3;
      font-family: $title;
      font-size: cp(16);
    }
  }

  &__figure {
    background-color: $color6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-height: cp(240);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__img {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);

    @media screen and (min-width: 768px) {
      max-height: cp(240 - 20);
    }
  }

  &__body {
    background-color: $color1;
    padding: cp(15) cp(20);
    flex: 1;

    &:not(:last-child) {
      @media screen and (min-width: 769px) {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 768px) {
      background-color: $color3;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      color: $color2;
      font-family: $title;
      font-size: cp(22);
      line-height: cp(22);
      text-transform: uppercase;
      border-radius: cp(20);
      padding: cp(17) cp(40);
      position: relative;
      display: inline-block;
      text-align: center;

      &:after {
        content: "";
        width: cp(14);
        height: cp(14);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.333' height='14.333' viewBox='0 0 14.333 14.333'%3E%3Cpath id='Path_10' data-name='Path 10' d='M7.167,0l-1.3,1.3L10.8,6.236H0V8.1H10.8L5.864,13.03l1.3,1.3,7.167-7.167Z' fill='%234d2883'/%3E%3C/svg%3E%0A");
        display: inline-block;
        position: absolute;
        right: cp(20);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__title {
    color: $color6;
    font-size: cp(17);
    line-height: 1em;
    font-family: $title;

    @media screen and (max-width: 768px) {
      color: $color2;
      display: inline;
    }
  }

  &__subtitle {
    color: $color6;
    font-size: cp(14);
    line-height: 1.286em;
    font-family: $regular;

    @media screen and (max-width: 768px) {
      color: $color2;
      font-family: $title;
      font-size: cp(17);
      line-height: 1em;
      color: #815cb8;
      display: inline;

      &:before {
        content: "-";
        margin: 0 5px;
      }

      span {
        display: none;
      }
    }
  }

  &__extra {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      font-size: cp(20);
    }
  }

  &__footer {
    background-color: $color1;
    padding: cp(15) cp(22);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  // context
  &__wrapper {
    max-width: 1210px;
    margin: 0 auto;

    @media screen and (max-width: 1240px) {
      max-width: 60vw;
    }

    @media screen and (max-width: 992px) {
      max-width: 70vw;
    }

    @media screen and (max-width: 768px) {
      max-width: calc(100% - 100px);
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -10px;
    min-width: 100%;
    margin-inline: auto;

    &:first-child {
      margin-bottom: cp(20);
    }

    &:last-child {
      li {
        &:first-child {
          .content {
            border-color: $color3;
          }

          p:first-child,
          p:last-child {
            color: $color3;
          }
        }

        &:last-child {

          @media screen and (min-width: 768px) {
            width: 50%;
          }

          .content {
            padding: 0;
            margin: 0;

            @media screen and (min-width: 768px) {
              padding-top: cp(70);
              padding-left: cp(70);
              padding-right: cp(75);
            }

            h3 {
              font-family: $black;
              font-size: cp(40);
              line-height: cp(51);
              color: $color6;
              margin-bottom: cp(15);
            }

            p {
              color: $color6;
              margin-bottom: cp(35);
            }

            .btn {
              display: inline-block;
              text-decoration: none;
              background-color: $color6;
              font-family: $title;
              font-size: cp(20);
              padding: cp(9.5) cp(35.74);
              transition: background .3s ease-in-out;

              &:hover,
              &:active,
              &:focus {
                background: $color3;
              }
            }
          }
        }
      }
    }

    li {
      padding: 0 10px;
      width: 25%;

      @media screen and (min-width: 768px) {
        min-height: 408px;
      }

      &:first-child {

        .content {
          height: 100%;
          padding: cp(20);
          border: 1px solid $color1;
          margin: 0;

          @media screen and (min-width: 768px) {
            padding: cp(30);
          }
        }

        p {
          font-family: $regular;
          font-size: cp(16);
          line-height: cp(25);
          color: $color6;
        }

        p:first-child {
          font-family: $bold;
          color: $color1;
          font-size: cp(18);
          line-height: cp(25);
          margin-bottom: cp(30);
        }

        p:last-child {
          font-size: cp(14);
          color: $color1;
          margin-top: cp(30);
        }
      }

      @media screen and (max-width: 1240px) {
        width: 50%;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

a.card {
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: translateY(-2.5%);
  }

  @media screen and (max-width: 768px) {
    transition: none;

    &:hover {
      transform: none;
    }
  }
}

.slider {
  max-width: 1180px;

  @media screen and (max-width: 1240px) {
    max-width: 80vw;
    overflow: visible;
  }

  @media screen and (max-width: 540px) {
    max-width: 50vw;
    overflow: visible;
  }

  &__item {
    text-decoration: none;
    display: block;

    &__image {
      width: 100%;
      height: auto;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &:not(:first-child) {
        margin-top: cp(30);
      }
    }

    &__title {
      font-size: cp(14);
      line-height: 1.286em;
      font-family: $title;
      text-transform: uppercase;
      color: $color4;
    }

    &__subtitle {
      font-size: cp(14);
      line-height: 1.143em;
      font-family: $regular;
      color: $color4;
      display: block;
    }
  }

  &__navigation {
    max-width: 1180px;
    position: relative;
    margin: 0 auto;
  }

  &__button {
    margin-top: cp(-190);
    width: cp(50);
    height: cp(50);
    background-color: $color1;
    border-radius: cp(10);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Path_10' data-name='Path 10' d='M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z' fill='%23fefeff'/%3E%3C/svg%3E%0A");
    background-position: center center;
    background-size: 30% 30%;
    background-repeat: no-repeat;

    &.swiper-button-disabled {
      display: none;
    }

    &:after {
      display: none;
    }

    &--prev {
      left: 0;
      transform: translateX(-150%) rotate(180deg);

      @media screen and (max-width: 1240px) {
        transform: translateX(100%) rotate(180deg);
      }
    }

    &--next {
      right: 0;
      transform: translateX(150%);

      @media screen and (max-width: 1240px) {
        transform: translateX(-100%);
      }
    }
  }
}

.footer {
  &__bg {
    &--desktop {
      transform: translate(6vw, 0);
      width: 100%;
      display: none;

      @media screen and (min-width: 992px) {
        display: block;
      }
    }

    &--mobile {
      display: block;
      width: 100%;

      @media screen and (min-width: 992px) {
        display: none;
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden;
    flex-direction: column;

    @media screen and (min-width: 992px) {
      flex-direction: row;
      margin-top: cp(60);
    }

    &__left {
      width: 100%;
      order: 2;
      flex: 1;
      padding: cp(30) cp(40);

      @media screen and (min-width: 992px) {
        order: unset;
        width: auto;
      }

      @media screen and (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__right {
      order: 1;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;

      @media screen and (min-width: 992px) {
        order: unset;
        width: auto;
      }
    }
  }

  &__bottom {
    background-color: $color8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: cp(40);
    position: relative;
    z-index: 100;

    @media screen and (min-width: 992px) {
      z-index: 5;
    }

    .to-top {
      opacity: 1;
      position: relative;
      margin: auto;
      top: cp(-100 + 30);
      left: 0;

      @media screen and (min-width: 992px) {
        display: none;
      }
    }

    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: cp(10);
      padding-top: cp(100);
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 992px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-bottom: cp(24);
      }

      &:nth-child(2) {
        &:not(:last-child) {
          margin-bottom: cp(8);
        }

        li {
          color: $color4;
          font-size: cp(11);
          font-family: $bold;
          line-height: 1.25em;
          padding: 0 cp(15);
          position: relative;

          @media screen and (max-width: 992px) {
            width: 100%;
            text-align: center;
            margin-bottom: cp(16);
          }

          &:after {
            content: "";
            width: 1px;
            height: cp(11);
            background-color: $color4;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (max-width: 992px) {
              display: none;
            }
          }

          &:first-child {
            padding-left: 0;

            @media screen and (max-width: 992px) {
              padding-left: cp(15);
            }
          }

          &:last-child {
            padding-right: 0;

            &::after {
              display: none;
            }

            @media screen and (max-width: 992px) {
              padding-right: cp(15);
            }
          }

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &:nth-child(3) {
        li {
          color: $color4;
          font-size: cp(10);
          font-family: $italic;
          line-height: 1.25em;

          &:not(:last-child) {
            padding-right: cp(15);
          }

          button {
            color: $color4;
            color: inherit;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.logo-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  li {
    margin-bottom: cp(10);

    &:not(:last-child) {
      margin-right: cp(30);
    }
  }

  &__item {
    &__img {
      max-width: cp(150);

      @media screen and (min-width: 992px) {
        max-width: cp(198);
      }
    }
  }
}

.to-top {
  position: fixed;
  right: cp(40);
  bottom: cp(40);
  width: 40px;
  height: 40px;
  border: 2px solid $color2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: #dff2f2;
  cursor: pointer;
  opacity: 0;
  transition: transform .25s ease-in-out, opacity .25s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:focus-visible {
    outline: cp(3) solid $color3;
  }

  @media screen and (max-width: 540px) {
    right: 30px;
    bottom: 30px;
  }
}

.labo {
  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container {
    max-width: 1180px;
    margin: 0 auto;
    padding: cp(60) cp(20);

    @media screen and (min-width: 992px) {
      padding: cp(100) cp(20) 0;
    }

    h3 {
      span {
        background-color: $color2;
      }

      @media screen and (min-width: 600px) {
        background-color: $color2;
      }
    }

    p {
      text-align: center;
      font-size: cp(18);
      line-height: cp(28);
      font-family: $black;
      color: $color1;

      @media screen and (min-width: 992px) {
        font-size: cp(35);
        line-height: cp(45);
      }
    }

    .btn {
      cursor: pointer;
      margin-top: cp(20);
      margin-left: auto;
      margin-right: auto;
      background-color: $color2;
      color: $color6;
      font-size: cp(22);
      min-width: cp(180);
      transition: background-color .25s ease-in-out, color .25s ease-in-out;

      &:hover {
        background-color: $color3;
        color: $color2;
      }

      @media screen and (min-width: 992px) {
        margin-top: cp(50);
      }
    }
  }
}