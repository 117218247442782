.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: cp(35);
	transform: translateX(-100%);
	opacity: 0;
	transition: opacity .25s ease-in-out;
	overflow-y: auto;
	background-color: rgba($color7, .25);

	@media screen and (min-width: 992px) {
		align-items: center;
	}

	&.active {
		opacity: 1;
		transform: translateX(0%);
	}

	&.close-modal {
		opacity: 0;
	}

	&__close-layer {
		cursor: pointer;
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__inner {
		z-index: 2;
		position: relative;
		background-color: $color6;
		padding: cp(35);
		width: 100%;
		max-width: cp(800);
		transition: transform .25s ease-in-out;
		border: cp(1) solid $color2;
		border-radius: cp(35);

		.content {
			margin: 0;
		}

		@media screen and (min-width: 992px) {
			padding: cp(60);
			border-radius: cp(60);
		}

		.close {
			width: cp(45);
			height: cp(45);
			position: absolute;
			top: cp((35 / 3));
			right: cp((35 / 3));
			font-family: $title;
			cursor: pointer;
			color: $color2;
			font-size: cp(28);
			transition: color .25s ease-in-out, transform .25s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (min-width: 992px) {
				top: cp((35 / 1.5));
				right: cp((35 / 1.5));
			}

			&:hover {
				color: darken($color2, 20%);
				transform: scale(1.1);
			}
		}

		.title {
			font-family: $title;
			color: $color2;
			font-size: cp(45);
			margin-bottom: cp(24);

			@media screen and (min-width: 992px) {
				margin-top: cp(-16);
			}
		}

		.content {
			color: $color4;
			font-size: cp(16);
			line-height: 1.563em;
			font-family: $regular;

			strong {
				color: $color2;
				font-size: cp(18);
				font-family: $bold;
				display: block;
				margin-top: cp(16);
			}

			a {
				text-decoration: underline;
				color: $color2;

				&:hover,
				&:active,
				&:focus {
					text-decoration: none;
				}

				&:focus-visible {
					outline: cp(2) solid $color2;
				}
			}

			.labo-list {
				margin-top: cp(16);

				@media screen and (min-width: 992px) {
					margin: cp(-16) cp(16);
				}

				li {
					color: $color2;
					border-bottom: cp(1) dashed lighten($color2, 50%);
					padding: cp(4);
					font-size: cp(14);

					@media screen and (min-width: 992px) {
						display: flex;
						align-items: center;
						font-size: cp(16);
						padding: cp(4) cp(32) cp(4) cp(12);
					}

					span {
						display: block;
						margin-left: auto;
					}
				}
			}
		}
	}
}