$color1: #44adaa;
$color2: #534394;
$color3: #ded434;
$color4: #7f7f7f;
$color5: rgba(#50b9b9, 0.18);
$color6: #fff;
$color7: #000;
$color8: #e4f2f2;

$sm: 667px;
$md: 1238px;
$lg: 1920px;

$regular: "merriweatherregular";
$italic: "merriweatheritalic";
$bold: "merriweatherbold";
$black: "merriweatherblack";
$title: "staatlichesregular";
