@import "../../styles/global/vars";
@import "../../styles/global/functions";

.bacheloreducation {
	background-color: rgba($color3, .2);
	padding-block: cp(80);
	margin-top: cp(250);

	@media screen and (min-width: 992px) {
		margin-bottom: cp(40);
		margin-top: cp(280);
	}

	&__container {
		width: 100%;
		padding-inline: cp(30);
		max-width: (775px + 60);
		margin-inline: auto;

		&--image {
			display: flex;
			justify-content: center;
			margin-top: cp(-241);
			margin-bottom: cp(72);

			figure {
				$size: 277;

				position: relative;
				margin-left: cp(-25);
				width: cp($size);
				height: cp($size);

				&::after {
					content: "";
					position: absolute;
					bottom: cp(-14);
					right: cp(-14);
					background-color: $color3;
					width: cp($size);
					height: cp($size);
					border-radius: cp($size * 2);
				}

				img {
					position: relative;
					z-index: 5;
					object-fit: contain;
					width: cp($size);
					height: ($size);
					border-radius: cp($size * 2);
				}

				figcaption {
					z-index: 6;
					$size: 119;
					position: absolute;
					top: cp(60);
					left: cp(-40);
					font-family: $regular;
					background-color: $color3;
					display: flex;
					align-items: center;
					justify-content: center;
					width: cp($size);
					height: cp($size);
					border-radius: cp($size);
					rotate: -12deg;
					font-size: cp(25);
					color: $color6;
					text-transform: uppercase;

					@media screen and (min-width: 600px) {
						top: cp(94);
						left: cp(-67);
					}
				}
			}
		}

		&--title {
			font-size: cp(35 - 5);
			line-height: cp(48 - 2.5);
			margin-bottom: cp(49);
			background-color: transparent;

			br {
				display: block !important;
			}

			span {
				margin-bottom: cp(5) !important;
				padding: cp(5) cp(10) 0 !important;
				background-color: $color1;
			}


			@media screen and (min-width: 992px) {
				background-color: $color1;
				width: cp(783);
				font-size: cp(35);
				line-height: cp(48);
				padding-top: cp(0);
				padding-bottom: cp(3);
				padding-left: cp(24);
				padding-right: cp(5);

				span {
					padding: 0 !important;
					margin-bottom: 0 !important;
				}

				br {
					display: none !important;
				}
			}
		}

		&--subtitle {
			color: $color2;
			font-family: $bold;
			font-size: cp(35 - 10);
			line-height: cp(44 - 10);
			margin-bottom: cp(46);

			@media screen and (min-width: 992px) {
				font-size: cp(35);
				line-height: cp(44);
			}
		}

		&--content {
			color: $color2;
			font-size: cp(20);
			line-height: cp(27);

			strong {
				font-family: $bold;
			}
		}

		p {
			margin-bottom: cp(30);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}