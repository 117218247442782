.cookie-bar {
	z-index: 800;
	position: fixed;
	bottom: 0;
	left: 0;
	min-height: cp(120);
	width: 100%;
	background-color: $color2;
	display: flex;
	align-items: center;
	padding: cp(35);
	transform: translateY(100%);
	transition: transform .25s ease-in-out;

	&.active {
		transform: translateY(0%);
	}

	&__inner {
		width: 100%;

		.content {
			font-size: cp(16);
			line-height: 1.563em;
			font-family: $regular;
			color: $color6;
			margin: 0;

			button {
				cursor: pointer;
				color: $color6;
				text-decoration: underline;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}

				&:focus-visible {
					outline: cp(2) solid $color3;
				}
			}
		}

		.accept {
			cursor: pointer;
			margin-top: cp(16);
			width: 100%;
			background-color: $color3;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
			color: $color2;
			font-family: $title;
			font-size: cp(16);
			line-height: 1em;
			text-transform: uppercase;
			border-radius: cp(20);
			padding: cp(17) cp(40);
			position: relative;
			display: inline-block;
			text-align: center;
			transition: color .25s ease-in-out, background-color .25s ease-in-out;

			&:hover,
			&:active,
			&:focus {
				background-color: $color6;
				color: $color2;
			}

			&:focus-visible {
				outline: cp(4) solid $color3;
			}
		}

		@media screen and (min-width: 992px) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			max-width: 1180px;
			margin: auto;

			.content {
				flex: 1;
				padding-right: cp(35);
			}

			.accept {
				margin-top: 0;
				margin-left: auto;
				width: auto;
			}
		}
	}
}